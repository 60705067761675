<template>
  <div>
    <m-bread-crumb/>

<section class="about spad">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-title about-title">
                        <u><h2>Estrutura de Governança</h2></u>
                        <br/> 
                        <br/>
                        <p style="text-align:justify">
                            O cliente tem importância estratégica nas decisões do AC MicroBanco, garantindo a perenidade da nossa instituição a partir do direito e do dever de atuar ativamente na Governança.
                            <br/> 
                            <br/>
                            O AC MicroBanco tem estrutura própria de Governança Corporativa, formada por Assembleia Geral, Conselho de Administração e Fiscal Único. Seguindo o modelo de Gestão Democrática, todos os membros da Assembleia e do Conselho de Administração têm direito a voto nos seus níveis de decisão. Esta estrutura está integrada no modelo de Governança Sistêmico do AC MicroBanco.
                            <br/> 
                            <br/>
                            A partir da gestão com base na Governança Corporativa ocorre uma mudança na forma de atuação do Conselho de Administração, sendo este o responsável pela execução das propostas aprovadas em Assembleia Geral, garantido se desta forma maior segurança.
                            <br/> 
                            </p>
                    </div>
                </div>
            </div>
            
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-title about-title">
                        <u><h2>Somos AC MicroBanco
                            De Moçambicanos Para Moçambicanos
                            </h2></u>
                        <br/> 
                        <br/>
                        <p style="text-align:justify">
                            <b>Resolver a vida financeira de cada cliente com soluções inteligentes e eficientes, adequadas às suas necessidades.</b> É pensando assim que o AC MicroBanco oferece produtos e serviços para ajudar você nos diferentes momentos da sua vida.
                            <br/> 
                            <br/>
                            Escolhemos seguir um caminho coletivo para oferecer soluções inteligentes para o desenvolvimento financeiro dos nossos clientes. Acreditamos que a escolha certa é a que é melhor para todos.
                            <br/> 
                            <br/>
                            Somos colaboradores que, todos os dias, encontramos conexões verdadeiras para fazer a diferença na vida dos nossos clientes. E isso nos mantém há oito anos consecutivos para trabalhar para si.
                            <br/>
                            Aqui, cada um encontra o seu ponto de conexão com o nosso propósito de fazermos juntos. <b>Faça a Diferença com Connosco!</b>
                            <br/> 
                            </p>
                    </div>
                </div>
            </div>
            
            
            <div class="about__feature">
                
                <div class="col-lg-12">
                    <div class="about__pic">
                        <img src="img/about/about-pic-02.jpg" alt="">
                    </div>
                </div>
            
            </div>
            
            
        </div>
    </section>
    
    

    
  </div>
</template>

<script>
import MBreadCrumb from "@/components/Layout/BreadCrumb";

export default {
  name: "Structure",
  components: { MBreadCrumb }
}
</script>

<style scoped>

</style>